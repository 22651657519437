import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 권한 그룹 관리 화면 관련 기능 클래스
 */
class AuthorityGroup {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 권한 그룹 관리 조회
     * @returns 
     */
    getAuthorityGroupInfo() {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'GetGroupMgmList';


            service.execute(request).then(response => {

                const result = response.data.result.value;

                if (!!result.Table.rows.length) resolve(result);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

     /**
     * 권한 그룹 저장
     * @param {Object} groupInfo 
     * @returns 
     */
    saveAuthorityGroupInfo(groupInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'CreateGroupMgm';
         
            request.parameters = groupInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 권한 그룹 수정
     * @param {Object} groupInfo 
     * @returns 
     */
    updateAuthorityGroupInfo(groupInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'UpdateGroupMgm';
         
            request.parameters = groupInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 권한 그룹 삭제
     * @param {Array} groupInfo 
     * @returns 
     */
     deletAuthorityGroupInfo(groupInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'DeleteGroupMgm';

            request.parameters.groupInfo = JSON.stringify(groupInfo);
            
            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 그룹별 메뉴 권한 관리 조회
     * @returns 
     */
     getAuthGroupMenuInfo(groupID) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'GetGroupAuthList';

            request.parameters.groupID = groupID;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                rows.forEach(row => {
                    
                    row.BasicAuth = row.BasicAuth === 1 ? true : false;
                    row.EditState = 'U';
                });

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 그룹별 메뉴 권한 관리 저장
     * @param {Array} groupMenuInfo 
     * @param {string} grpID
     * @returns 
     */
        saveAuthGroupMenuInfo(groupMenuInfo, grpID) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'SaveGroupAuth';
         
            request.parameters.groupMenuInfo = JSON.stringify(groupMenuInfo);
            request.parameters.grpID = grpID;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 그룹별 메뉴 권한 관리 삭제
     * @param {Array} groupMenuInfo
     * @param {string} grpID 
     * @returns 
     */
     deletAuthGroupMenuInfo(groupMenuInfo, grpID) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.GroupUserBiz';
            request.methodId = 'DeleteGroupMenuAuth';

            request.parameters.groupMenuInfo = JSON.stringify(groupMenuInfo);
            request.parameters.grpID = grpID;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const authorityGroup = new AuthorityGroup();