<template>
    <div class="admin-content">
		<h3>권한 그룹 관리</h3>

        <div class="flex-wrap half">
            <div class="col-6">
                <div class="flex-wrap right">
                    <div class="ml10">
                        <div class="btn-wrap">
                            <button type="button" 
                                class="btn-box green"
                                @click="eventHandler.dataTable.authorityGroupRowAdd">신규</button>
                            <button type="button" 
                                class="btn-box red"
                                @click="eventHandler.dataTable.authorityGroupRowRemove">삭제</button>
                        </div>
                    </div>
                </div>

                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.authorityGroup === null ? 0 : state.dataTable.authorityGroup.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.authorityGroup"
                    :resizableColumns="true"
                    editMode="row"
                    dataKey="GrpID"
                    :rowHover="true"
                    v-model:editingRows="state.dataTable.rowDataModel"
                    @row-edit-init="eventHandler.dataTable.onRowEditInit"
                    @row-edit-save="eventHandler.dataTable.onRowEditSave"
                    @row-edit-cancel="eventHandler.dataTable.onRowEditCancel"
                    @row-dblclick="eventHandler.dataTable.onRowClick">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.IsChecked" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="GrpID" header="권한그룹ID" :sortable="true" style="width:100px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" ref="grpID" v-model="data[ field ]"  />
                        </template>
                    </Column>
                    <Column field="GrpName" header="그룹명" :sortable="true" style="width:140px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" ref="grpName" v-model="data[ field ]"  />
                        </template>
                    </Column>
                    <Column field="UseYN" header="사용여부" :sortable="true" style="text-align:center; width:80px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <div class="select-box">
                               <select v-model="data[field]">
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                               </select>
                            </div>
                        </template>
                    </Column>
                    <Column :rowEditor="true" style="width:100px;"></Column>
                </DataTable>
            </div>
            <div class="col-6">
                <div class="flex-wrap right">
                    <div class="ml10">
                        <div class="btn-wrap">
                            <button type="button" 
                                class="btn-box"
                                @click="methods.getAuthorityGroupInfo">조회</button>
                            <button type="button"
                                class="btn-box green"
                                @click="{
                                    if(!!state.dataTable.selectedGrpID){
                                        state.dialog.isOpen=true;
                                    }
                                }">신규</button>
                            <button type="button" 
                                class="btn-box red"
                                @click="eventHandler.dataTable.authGroupMenuRowRemove">삭제</button>
                            <button type="button" 
                                class="btn-box gray"
                                @click="methods.saveAuthGroupMenuInfo">저장</button>
                        </div>
                    </div>
                </div>
                
                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.authGroupMenu === null ? 0 : state.dataTable.authGroupMenu.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.authGroupMenu"
                    :resizableColumns="true"
                    :rowHover="true">

                    <template #empty>
                        데이터가 없습니다.
                    </template>
                    
                    <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.IsChecked" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="MenuID" header="메뉴ID" :sortable="true" style="width:100px;" headerClass="tc"></Column>
                    <Column field="MenuName" header="메뉴명" :sortable="true" style="width:150px;" headerClass="tc"></Column>
                    <Column field="BasicAuth" header="권한" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.BasicAuth" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="MenuPath" header="메뉴경로" :sortable="true" style="width:120px;" headerClass="tc"></Column>

                </DataTable>
            </div>
        </div>
    </div>

    <Dialog 
        :header="state.dialog.header"
        :visible="state.dialog.isOpen"
        :modal="true"
        @show="eventHandler.dialog.show"
        @hide="eventHandler.dialog.hide"
        @close="state.dialog.isOpen=false"
        @update:visible="state.dialog.isOpen = false">

        <DataTable class="p-datatable-sm"
            scrollHeight="497px"
            :value="state.dataTable.authGroupMenuDetail"
            :resizableColumns="true"
            :rowHover="true">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="MenuID" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                <template #body = {data}>
                    <div class="chk-box">
                        <input type="checkbox" v-model="data.DetailChecked" :binary="true"/>
                        <label></label>
                    </div>
                </template>
            </Column>
            <Column field="MenuID" header="메뉴ID" :sortable="true" style="width:100px;" headerClass="tc"></Column>
            <Column field="MenuName" header="메뉴명" :sortable="true" style="width:200px;" headerClass="tc"></Column>
            <Column field="BasicAuth" header="수정권한" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                <template #body = {data}>
                    <div class="chk-box">
                        <input type="checkbox" v-model="data.BasicAuth" :binary="true"/>
                        <label></label>
                    </div>
                </template>
            </Column>
            <Column field="MenuPath" header="메뉴경로" :sortable="true" style="width:120px;" headerClass="tc"></Column>
        </DataTable>

        <template #footer>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button type="button" 
                                class="btn-box"
                                @click="eventHandler.dataTable.authGroupMenuRowAdd">확인</button>
                    </div>
                </div>
            </div>
        </template>
    </Dialog>

</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { authorityGroup } from '@/modules/system/PCM_SM0400E.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
    },
    setup() {

        // 유효성 검사 ref
        const grpID = ref(); // 권한그룹ID
        const grpName = ref(); // 그룹명

        // 스토어
        const store = useStore();

        // state
        const state = reactive({
            
            // 데이터 테이블 state
            dataTable: {

                // 그룹 권한 목록
                authorityGroup: null,

                // 권한 그룹별 메뉴 목록
                authGroupMenu: null,

                // 권한 그룹별 메뉴 상세 목록
                authGroupMenuDetail: null,

                // 행 데이터 모델
                rowDataModel: new Array(),

                // 선택한 그룹 ID
                selectedGrpID: null,

            },

            // 체크 박스 체크여부 판별 
            IsCheckYn: {

                // 그룹 권한 목록
                authorityGroup: null,

                // 권한 그룹별 메뉴 목록
                authGroupMenu: null,
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "그룹별 메뉴 권한 관리",

                // 다이얼로그 오픈여부
                isOpen: false,
            },

            dropDown: {
                items: new Array(),
            },
        });

        // 메서드
        const methods = {

            // 권한 그룹 목록 조회
            getAuthorityGroupInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                authorityGroup.getAuthorityGroupInfo().then(result => {

                    // 권한 그룹 관리
                    state.dataTable.authorityGroup = result.Table.rows;

                    // 그룹별 메뉴 권한 관리 (신규용)
                    state.dataTable.authGroupMenuDetail = result.Table1.rows;

                    state.dataTable.authGroupMenuDetail.forEach(row => {

                        row.BasicAuth = row.BasicAuth === 1 ? true : false;
                    });

                }).finally(() => {

                    // 선택한 그룹 아이디 초기화
                    state.dataTable.selectedGrpID = null;

                    // 권한 그룹별 메뉴 목록 초기화
                    state.dataTable.authGroupMenu = null,

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                    
                });
            },

            // 권한 그룹 저장
            saveAuthorityGroupInfo: (groupInfo) => {

                alertManager.confirm.save().then(() => { 
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    authorityGroup.saveAuthorityGroupInfo(groupInfo)

                    .then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.add();

                            // 목록 재조회
                            methods.getAuthorityGroupInfo();
                        }
                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                });                
            },

            // 권한 그룹 수정
            updateAuthorityGroupInfo: (groupInfo) => {

                alertManager.confirm.edit().then(() => { 
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
                    
                    authorityGroup.updateAuthorityGroupInfo(groupInfo)
                    
                    .then((response) => {
                    
                        if(response.data.success) {
                        
                            alertManager.toast.save();
                        
                            // 목록 재조회
                            methods.getAuthorityGroupInfo();
                        }
                    })
                
                    .finally(() =>{
                    
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    
                    });
                });
            },

            // 권한 그룹 삭제
            deletAuthorityGroupInfo: (groupInfo) => {

                alertManager.confirm.remove().then(() =>{
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    authorityGroup.deletAuthorityGroupInfo(groupInfo).then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.remove();

                            // 목록 재조회
                            methods.getAuthorityGroupInfo();
                        }
                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                });
            },

            // 그룹별 메뉴 권한 관리 조회
            getAuthGroupMenuInfo: (groupID) => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                authorityGroup.getAuthGroupMenuInfo(groupID).then(result => {

                    state.dataTable.authGroupMenu = result;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 그룹별 메뉴 권한 관리 저장
            saveAuthGroupMenuInfo: () => {

                if (!state.dataTable.authGroupMenu) {

                    return
                }

                alertManager.confirm.save().then(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    state.dataTable.authGroupMenu.forEach(row => {
                        row.BasicAuth = row.BasicAuth === true ? 1 : 0;
                        row.ExtAuth = row.EditState === 'I' ? 0 : row.ExtAuth;
                    });

                    authorityGroup.saveAuthGroupMenuInfo(state.dataTable.authGroupMenu, state.dataTable.selectedGrpID)

                    .then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.save();
                        }
                    })

                    .finally(() =>{

                        state.dataTable.authGroupMenu.forEach(row => {
                            row.BasicAuth = row.BasicAuth === 1 ? true : false;
                            row.EditState = 'U';
                        });
                        
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                });
            },

            // 그룹별 메뉴 권한 관리 삭제
            deletAuthGroupMenuInfo: (groupMenuInfo, authGroupMenuList) => {

                if(state.dataTable.authGroupMenu.length === authGroupMenuList.length) return;

                alertManager.confirm.remove().then(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    authorityGroup.deletAuthGroupMenuInfo(groupMenuInfo, state.dataTable.selectedGrpID).then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.remove();
                        }
                    })

                    .finally(() =>{

                        //신규추가 삭제 
                        if(!!authGroupMenuList) state.dataTable.authGroupMenu = authGroupMenuList;
                        
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                });
            },
            // 그룹 권한 정보 유효성검사
            grpValidation: (groupInfo) => {

                let validator = state.dataTable.rowDataModel;
        
                //권한그룹ID
                if (!validator.GrpID) {
                    alertManager.alert.warning('권한그룹ID를 입력해주세요');

                    return;
                }
                // 그룹명
                else if (!validator.GrpName) {
                    alertManager.alert.warning('그룹명을 입력해주세요');

                    return;
                }
                else {

                    // 권한그룹 상세 저장
                    if (groupInfo.type === 'I') methods.saveAuthorityGroupInfo(groupInfo);
                    // 권한그룹 상세 수정
                    else methods.updateAuthorityGroupInfo(groupInfo);
                }
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {

                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 그룹별 메뉴 권한 관리 조회
                    methods.getAuthGroupMenuInfo(event.data.GrpID);

                    // 선택한 그룹 아이디 바인딩
                    state.dataTable.selectedGrpID = event.data.GrpID;
                },
                // 행 수정 모드 이벤트 처리
                onRowEditInit: event => {

                    // 행 수정 모드 설정
                    event.data.isEdit = true;

                },
                // 행 수정 이벤트 처리
                onRowEditSave: event => {

                    // 수정 여부 초기화
                    state.dataTable.authorityGroup.forEach(row => { if (row.isEdit === true) row.isEdit = false });
                    
                    // 변경 된 데이터 바인딩
                    state.dataTable.rowDataModel = event.newData;

                    // 수정전 행의 데이터가 있었는지 여부 판별 - 없는 경우 저장, 있는 경우 수정 
                    if (event.data.GrpID === null) {
                        
                        event.newData.type = 'I';
                    } else {

                        event.newData.type = 'U';
                    }

                    // 유효성 검사
                    methods.grpValidation(event.newData);
                    
                    // 행 데이터 모델 배열로 초기화
                    state.dataTable.rowDataModel = new Array();
                },
                onRowEditCancel: () => {

                    // 수정 여부 초기화
                    state.dataTable.authorityGroup.forEach(row => { if (row.isEdit === true) row.isEdit = false });
                },

                // 권한 그룹 관리 행 추가 이벤트 처리
                authorityGroupRowAdd: () => {

                    // 이전 신규 행을 작업중이거나 기존행 수정중일 경우 처리
                    for (let row = 0; row < state.dataTable.authorityGroup.length; row++) {

                        const rows = state.dataTable.authorityGroup[row];

                        if (rows.isNew || rows.isEdit) {

                            // alert 명칭 수정
                            alertManager.alert.warning('이전에 추가한 신규 또는 기존 수정중인 행이 있습니다.')
                            return;
                        }
                    }

                    // 신규 행 추가
                    state.dataTable.authorityGroup.unshift(
                        { GrpID: null, GrpName: null, UseYN: 'Y', isNew: true }
                    )
                },

                // 권한 그룹 관리 행 삭제 이벤트 처리
                authorityGroupRowRemove: () => {

                    // 삭제될 권한그룹
                    let deleteAuthorityGroupList = new Array();

                    // 신규 행 삭제 처리
                    state.dataTable.authorityGroup = state.dataTable.authorityGroup.filter((item) => item.isNew !== true);

                    // 기존 행 삭제 데이터 가공 // 체크 된 데이터
                    state.dataTable.authorityGroup.forEach(row => {
                        
                        if(row.IsChecked === true) deleteAuthorityGroupList.push(row)
                    });

                    // 삭제 할 데이터 존재시 삭제 처리
                    if(deleteAuthorityGroupList.length > 0) {

                        methods.deletAuthorityGroupInfo(deleteAuthorityGroupList);
                    }
                },

                // 그룹별 메뉴 관리 행 추가 이벤트 처리
                authGroupMenuRowAdd: () => {

                    // 중복 여부 체크 // 기존에 존제하는 행은 추가 되지 않도록 체크 제외
                    if (!!state.dataTable.authGroupMenu) {

                        state.dataTable.authGroupMenu.forEach(authGroupMenuRow => {

                            state.dataTable.authGroupMenuDetail.forEach(authGroupMenuDetailRow => {

                                if (!!authGroupMenuDetailRow.DetailChecked && authGroupMenuRow.MenuID === authGroupMenuDetailRow.MenuID) {

                                    authGroupMenuDetailRow.DetailChecked = false;
                                }
                            });
                        });
                    }

                    // 그룹별 메뉴관리 행 추가
                    state.dataTable.authGroupMenuDetail.forEach(row => {

                        // 기존 행 삭제 데이터 가공 // 체크 된 데이터
                        if (!!row.DetailChecked) {

                            if (!!state.dataTable.authGroupMenu) {

                                state.dataTable.authGroupMenu.push(row);
                            } else {

                                state.dataTable.authGroupMenu = new Array(row);
                            }

                            // 그룹별 메뉴관리 신규 추가 상태값
                            row.EditState = 'I';
                        }
                    })

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                },

                // 그룹별 메뉴 관리 행 삭제 이벤트 처리
                authGroupMenuRowRemove: () => {
                    
                    // 삭제될 그룹별 메뉴 아이디
                    let deleteAuthGroupMenuID = new Array();

                    // 신규 삭제 제회한 메뉴 목록
                    let authGroupMenuList = new Array();

                    // 삭제될 그룹별 메뉴 목록
                    let deleteAuthGroupMenuList = new Array();
                    
                    // 삭제될 그룹별 메뉴 신규가 아닌 MenuID 값 추출
                    state.dataTable.authGroupMenu.forEach(row => {
                        
                        if(!!row.IsChecked && row.EditState != 'I') deleteAuthGroupMenuID.push(row.MenuID);
                        else if(!row.IsChecked) authGroupMenuList.push(row);
                        
                        //체크값 초기화
                        row.IsChecked = false;
                    });

                    // 삭제될 그룹별 MenuID 값 기존 데이터와 비교 // 삭제될 행만 데이터가공
                    deleteAuthGroupMenuID.forEach(menuID => {
                        state.dataTable.authGroupMenu.forEach(menuInfo => {
                            if(menuID === menuInfo.MenuID) deleteAuthGroupMenuList.push(menuInfo)
                        });
                    });

                    methods.deletAuthGroupMenuInfo(deleteAuthGroupMenuList, authGroupMenuList);
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {

                },

                // hide 이벤트 처리
                hide: () => {

                    state.dataTable.authGroupMenuDetail.forEach(row => {

                        if (!!row.DetailChecked) {
                            row.DetailChecked = false;
                        }
                    });
                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 권한 그룹 목록 초기조회
            methods.getAuthorityGroupInfo();

        });

        return {
            state,
            authorityGroup,
            methods,
            eventHandler,
            grpID,
            grpName,
        }
    }
}
</script>